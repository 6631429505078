<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Lineas De Negocio</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Lineas De Negocio</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_linea_negocio_export"
                        v-if="$store.getters.can('admin.lineasNegocios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-on:click="abrirModal('Agregar', null)"
                        data-toggle="modal"
                        data-target="#Modal_lineas"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  id="renderLineas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          v-on:change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in lineasNegocio.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>
                        <span
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_lineas"
                            v-if="
                              $store.getters.can('admin.lineasNegocios.show')
                            "
                            v-on:click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-on:click="destroy(item.id)"
                            v-if="
                              $store.getters.can('admin.lineasNegocios.delete')
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="lineasNegocio.total">
                  <p>
                    Mostrando del <b>{{ lineasNegocio.from }}</b> al
                    <b>{{ lineasNegocio.to }}</b> de un total:
                    <b>{{ lineasNegocio.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="lineasNegocio"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Modal de agregar y editar -->
      <div class="modal fade" id="Modal_lineas">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <!-- campo Nombre -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="descripcion">Descripción</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre"
                      placeholder="Nombre Linea Negocio"
                      v-model="form.nombre"
                      :class="
                        $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      @blur="validaNombre"
                    />
                  </div>
                </div>
                <!-- /. campo Nombre -->
                <!-- Campo Estado -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.estado"
                      :class="
                        $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- /. Campo Estado -->
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.form.$invalid"
                v-on:click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
    <LineaNegocioExport ref="LineaNegocioExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required, minLength } from "vuelidate/lib/validators";
import $ from "jquery";
import LineaNegocioExport from "./LineaNegocioExport";

export default {
  name: "LineasNegocios",
  components: {
    pagination,
    LineaNegocioExport,
  },
  data() {
    return {
      lineasNegocio: {},
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        nombre: null,
        estado: null,
      },
      listasForms: {
        estados: [],
        tipo_docs: [],
      },
      form: {
        nombre: "",
        estado: "",
      },
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    //metodo de apertura del modal
    abrirModal(accion, idlinea) {
      this.modal.accion = accion;
      this.modal.title = accion + " Linea de negocio";
      if (accion == "Editar") {
        this.show(idlinea);
      } else {
        this.form = {}; // se limpian los campos de formulario
      }
    },
    getIndex(page = 1) {
      axios
        .get("/api/admin/lineasNegocios?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.lineasNegocio = response.data;
        });
    },
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    validaNombre() {
      if (this.form.nombre) {
        let id = [];
        if (this.form.id) {
          id.push(this.form.id);
        }
        let filtro = {
          not_id: id,
          nombre_igual: this.form.nombre,
        };
        axios
          .get("/api/admin/lineasNegocios/lista", {
            params: filtro,
          })
          .then((response) => {
            if (response.data.length > 0) {
              this.$swal({
                icon: "error",
                title: "La línea de negocio digitada ya está creada...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.form.nombre = null;
            }
          });
      }
    },
    save() {
      if (!this.$v.form.$invalid) {
        axios
          .post("/api/admin/lineasNegocios", this.form)
          .then((response) => {
            this.form = {};
            this.getIndex(); //Lista de nuevo
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            $(function () {
              $("#Modal_lineas").modal("toggle");
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    show(idlinea) {
      axios
        .get("/api/admin/lineasNegocios/show/" + idlinea)
        .then((response) => {
          this.form = response.data;
        });
    },
    destroy(idlinea) {
      this.$swal({
        title: "Esta seguro de eliminar esta Linea De Negocio?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/lineasNegocios/" + idlinea).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Linea De Negocio de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>